@import 'settings';
@import 'foundation';
@include foundation-everything;

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-variant-caps: small-caps;
}

/* Less stylish text for About and Privacy pages. */
.straight-text {
  p, dl, dt, dd, small {
    font-family: 'Open Sans', sans-serif;
    font-variant: normal;
  }
}

/*
 * Topbar
 */
.top-bar {
  color: $white;
  a, a::after {
    color: $white;
  }
  ul.dropdown.menu {
    li.is-dropdown-submenu-parent .first-sub {
    }
    li.is-dropdown-submenu-parent .second-sub {
      min-width: 500px;
    }
  }
  /* Logotype */
  .top-bar-left {
    font-size: 1.4rem;
    font-variant-caps: small-caps;
    padding-left: 1rem;
  }
  .top-bar-right {
    font-size: 1.2rem;
    font-variant-caps: all-small-caps;
    a:focus, a:hover {
      text-decoration: underline;
    }
  }
}

.page-content {
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
  position: fixed;
}

#dialog {
  width: 100%;
  height: 100%;
  position: fixed;
}

.button {
  padding-top: .4em;
  padding-bottom: .4em;
}

/*
 * Callout is used for presenting questions and choices on the detail page
 * and summarizing stats on the results page.
 */
.callout {
  background-color: $black;
  opacity: 0.9;
  border-color: $white;
  font-family: 'Montserrat', sans-serif;
  font-variant-caps: all-small-caps;
  font-size: 1.2rem;
  p, a {
    font-size: 1em;
  }
}
// Class to modify Callout on detail page.
.detail {
}
// Bumps up the bottom to account for space taken by AddThis bar.
.results {
  margin-bottom: 64px;
}

/* For choices on detail pages */
button.clear.choice {
  color: $white;
}

/* For previous and next poll links */
.next-poll {
  color: $white;
  font-family: 'BenchNine', sans-serif;
  font-variant: normal;
  font-size: 5rem;
  font-weight: 800;
  text-align: center;
  z-index: 1;
  a {
  }
  a:link, a:visited {
    color: $white;
  }
  a:focus, a:hover {
    color: $medium-gray;
  }
  a:active {
    color: $dark-gray;
  }
}

/* Media query section */
/* Small only */
@media screen and (max-width: 39.9375em) {
  h1 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0.4rem;
  }
  h2 {
    font-size: 17px;
    font-weight: 600;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  h1 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0.4rem;
  }
  h2 {
    font-size: 17px;
    font-weight: 600;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
  }
}

/* Large and up */
@media screen and (min-width: 64em) {
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 0.4rem;
  }
  h2 {
    font-size: 1.6rem;
    font-weight: 600;
  }
  h3 {
    font-size: 1.4rem;
    font-weight: 600;
  }
}

